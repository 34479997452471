// im webfont
.im-icon{
    transition: .2s ease;
    font-size: $fontsize-l;
    &.icon-xxl{
        font-size: 18px;
    }
    &.icon-xl{
        font-size: 16px;
    }
    &.icon-l{
        font-size: $fontsize-s;
    }
    &.icon-s{
        font-size: 9px;
    }
    &.icon-xs{
        font-size: 7px;
    }
    &.icon-xxs{
        font-size: 6px;
    }
}

// customize
.icon-close{
    color: #acacac;
    font-size: 24px;
}
.icon{
    display: inline-block;
    flex-shrink: 0;
}
i.icon-basket {
    width: 17px;
    height: 21px;
    background: url('/assets/img/icons/icon-basket.svg') center center no-repeat;
    background-size: contain;
}
i.icon-fys {
    width: 24px !important;
    height: 20px;
    background: url('/assets/img/icons/icon-fys.svg') center center no-repeat;
    background-size: contain;
}