.show--tablet{
	display: none;
}
// @media only screen and (min-width: 700px) {
	.warn-product-not-available{
		margin-top: 5px;
	}
// }
// @media only screen and (min-width: 1024px) {
@media only screen and (min-width: 700px) {
	.page{
		padding-bottom: 32px !important;
	}
	.show--tablet{
		display: block;
	}
    .contain{
        padding-left: $spacing-m;
        padding-right: $spacing-m;
    }
	.orders{
		margin-right: 0 !important;
	}
	.h-tags{
		margin-right: 0 !important;
	}
	app-dashboard .cta{
		padding: $spacing-l !important;
		// padding-left: 245px !important;
		// padding-left: 25% !important;
		height: 240px !important;
		.bg{
			background-size: 170px !important;
			background-position: right 280px bottom 0px !important;
			background-position: left 50% bottom 0px !important;
			background-position: left 0 bottom 0px !important;
			left: 50% !important;
		}
		h2{
			// max-width: 230px !important;
			// max-width: unset !important;
			font-size: 25px !important;
			max-width: calc(50% - 60px) !important;
		}
	}
	app-dashboard .msg{
		// max-width: 410px;
	}
	.basket-msg{
		padding: $spacing-l !important;
		// padding-left: 230px !important;
		// padding-left: 24% !important;
	}
	.button-default-shadow, .basket .button.default, .my-products-detail .button.green {
		max-width: 360px !important;
		margin-left: auto;
		margin-right: auto;
	}
	.sticky-basket{
		.name{
			font-size: $fontsize-l !important;
		}
		.article{
			font-size: $fontsize-m !important;
		}
		.button.green{
			max-width: 360px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
