.tip {
    position: relative;
}

.tip::before,
.tip::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    pointer-events: none;
}

.tip:hover::before,
.tip:focus::before,
.tip:hover::after,
.tip:focus::after {
    opacity: 1;
}

.tip::before {
    // border-style: solid;
    // border-width: 1em 0.75em 0 0.75em;
    // border-color: #3E474F transparent transparent transparent;
    // bottom: 100%;
    // content: "";
    // margin-left: -0.5em;
    // transform:  scale(.6) translateY(-90%);
} 

.tip:hover::before,
.tip:focus::before {
    // transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
}

.tip::after {
    background: #606060;
    border-radius: 3px;
    bottom: 100%;
    color: #ffffff;
    content: attr(data-tip);
    padding: 6px 8px;
    font-size: $fontsize-s;
    transform: translatex(-50%) translatey(-6px);
    transition: .2s ease;
    white-space: nowrap;
}
.tip-right::after{
    bottom: auto;
    left: 100%;
    transform: translatex(3px) translatey(-52%);
    top: 50%;
}