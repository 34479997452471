// colors
:root{
    --text-color: #222222;
    --background-color: #ffffff;
    --primary-color: #2c70be;
    --secondary: #2c70be !important;
    --tertiary: #2c70be;
    --green: #57cc99;
}

// font sizes
$fontsize-xl: 28px;
$fontsize-l: 18px;
$fontsize-m: 16px;
$fontsize-s: 14px;
$fontsize-xs: 13px;
$fontsize-xxs: 12px;

// spacing
$spacing-xxl: 64px;
$spacing-xl: 48px;
$spacing-l: 40px;
$spacing-m: 32px;
$spacing-s: 24px;
$spacing-xs: 16px;
$spacing-xxs: 8px;
$spacing-xxxs: 6px;