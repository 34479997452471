@import "./assets/scss/reset.scss";
@import '~bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr';
@import "./assets/scss/variables.scss";
@import "./assets/scss/helpers.scss";
@import "./assets/scss/animations.scss";
@import "./assets/scss/tooltips.scss";
@import "./assets/scss/ie.scss";
@import "./assets/scss/icons.scss";
@import "./assets/scss/im-webfont.scss";
@import "./assets/scss/fonts.scss";


// general
* {
    touch-action: manipulation;
}
.page{
    padding-top: 58px !important;
    min-height: 100vh !important;
    padding-bottom: 70px !important;
}
.page--login{
    padding-top: 0 !important;
}
::placeholder {
    color: #acacac;
    opacity: 1;
}
body {
    font-size: $fontsize-m;
    line-height: 1;
    background: rgb(244, 244, 244) !important;
}
body * {
    font-family: proxima-nova, sans-serif;
}
h1, h2, h3, h4{
    margin: 0;
    color: var(--text-color);
}
h1{
    font-size: $fontsize-xl;
}
h2{
    font-weight: 600;
    font-size: 24px;
}
h3{
    font-size: $fontsize-m;
    font-weight: 600;
}
h4{
    font-size: $fontsize-l;
}
.scroll-y{
    overflow-y: auto;
}
.scroll-x{
    overflow-x: auto;
}
.container {
    // max-width: 400px !important;
    max-width: 1024px !important;
    padding: 0;
    position: relative;
    background: white;
}
.contain{
    padding-left: $spacing-xs;
    padding-right: $spacing-xs;
}
.description{
    font-weight: 300;
    line-height: 24px;
    font-size: $fontsize-m;
    padding-top: 6px;
    p{
        margin-bottom: 8px;
    }
    strong{
        font-weight: 600;
    }
    ul{
        // list-style-type: disc;
        margin-bottom: 8px;
        li{
            padding-left: 12px;
            position: relative;
            &::before{
                content: '';
                width: 4px;
                height: 4px;
                background: #afaeb3;
                display: block;
                border-radius: 100%;
                position: absolute;
                left: 1px;
                top: 10px;
            }
        }
    }
}
// 

.hide--price {
    .--price{
        visibility: hidden;
    }
    .--price--block{
        display: none;
    }
}

.empty-state{
    &>div{
        color: #747375;
        line-height: 1.2;
        margin-bottom: 15px;
    }
}

body div .language-switch{
    span{
        color: #7e858e;
        font-weight: 300;
    }
    select{
        display: inline-block;
        width: auto;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        padding: 1px 4px;
        background: #ffffff url(/assets/img/icons/icon-dropdown-darker.svg) right 10px bottom 2px no-repeat;
        background-size: 13px 13px;
        padding-right: 23px;
        color: #7e858e;
        min-height: 0;
    }
    .arrow-fit{
        color: #7e858e;
    }
}
.button-default-shadow{
    box-shadow: 0px 8px 16px 0px rgba(44, 112, 190, 0.12);
    border-radius: 4px;
}
.button-gray-shadow{
    box-shadow: 0px 8px 16px 0px rgba(90, 90, 90, 0.15);
    border-radius: 4px;
}
.arrow-line{
    &:hover{
        // background: #fcfcfc;
    }
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    border-bottom: 1px solid #ecebec;
    position: relative;
    display: block;
    cursor: pointer;
    text-decoration: none !important;
    *{
        text-decoration: none !important;
    }
    .arrow-line-main{
        color: var(--text-color);
        font-size: $fontsize-m;
        margin-bottom: 3px;
    }
    .arrow-line-sub{
        color: #747375;
        font-size: $fontsize-s;
        .dot-sep{
            margin-bottom: 2px;
        }
    }
    .im-icon-chev-right{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translatey(-50%);
        color: #909090;
        font-size: 16px;
    }
}
.dot-sep{
    background: #747375;
    width: 4px;
    height: 4px;
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 100%;
}
.sep{
    background: #ecebec;
    height: 1px;
    width: 100%;
    margin-top: $spacing-s;
    margin-bottom: $spacing-s;
    &.sep--black{
        background: var(--text-color);
    }
}

// helpers
.transition{
    transition: .2s ease;
}
.saving{
    opacity: .7;
    pointer-events: none;
}
.opacity-hide{
    transition: .2s ease;
    opacity: 0;
    pointer-events: none;
}
.opacity-show{
    opacity: 1 !important;
    pointer-events: all !important;
}
.lh-1{
    line-height: 1;
}
.h-100{
    height: 100%;
}
.uppercase{
    text-transform: uppercase;
}

// toasts
#toast-container{
    max-width: 100%;
    padding-left: 24px;
    .ngx-toastr{
        max-width: 100%;
        box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.2) !important;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 53px;
        padding-right: 30px;
        border-radius: 4px;
        background: #ffffff;
        margin: 0 0 11px;
        width: 400px;
        .toast-close-button{
            background-image: url(/assets/img/icons/x.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 10px;
            height: 10px;
            transition: .1s ease;
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translatey(-50%);
            &:hover{
                opacity: .7;
            }
            span{
                display: none;
            }
        }
    }
    .toast-success{
        border-left: 5px solid var(--green);
    }
    .toast-warning{
        border-left: 5px solid var(--orange);
    }
    .toast-error{
        border-left: 5px solid var(--red);
    }
    .toast-info{
        border-left: 5px solid var(--secondary);
    }
    .toast-bottom-right {
        right: 16px;
        bottom: 0px;
    }
    .toast-title{
        font-size: $fontsize-m;
        color: var(--text-color);
        font-family: "uni_neueregular", sans-serif;
    }
    .toast-message{
        font-size: $fontsize-xs;
        color: #555555;
        margin-top: 5px;
    }
    .toast-warning{
        background-image: url(/assets/img/icons/icon-toast-warning.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-error{
        background-image: url(/assets/img/icons/icon-toast-error.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-info{
        background-image: url(/assets/img/icons/icon-toast-info.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-success{
        background-image: url(/assets/img/icons/icon-toast-success.svg);
        background-repeat: no-repeat;
        background-size: 21px 21px;
        background-position: center left 15px;
    }
    
}
// display
.display-label{
    font-size: $fontsize-s;
    line-height: 1;
    margin-bottom: 7px;
    color: #afaeb3;
    font-weight: 600;
}
.display-row{
    margin-bottom: $spacing-s;
    .display-value{
        margin-bottom: $spacing-xs;
        font-size: $fontsize-m;
        color: var(--text-color);
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    &:last-of-type{
        margin-bottom: 0;
    }
    .display-content{
        margin-bottom: $spacing-m;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}

// buttons
.button{
    transition: 0.2s ease;
    color: #ffffff;
    box-shadow: none !important;
    outline: none !important;
    padding: 13px 16px;
    background: var(--primary-color);
    border-radius: 4px;
    border: none;
    height: 0%;
    display: flex;
    align-items: center;
    &:hover{
        background: var(--primary-color);
        filter: brightness(1.1);
    }
    &.default{
        background: var(--secondary);
        font-size: 20px;
        justify-content: center;
        &:hover{
            background: var(--secondary);
            filter: brightness(1.1);
        }
        &:disabled,
        &.disabled{
            pointer-events: none;
            background: #afaeb3 !important;
        }
    }
    &.default--light{
        padding: 7px 24px;
        background: transparent;
        font-size: 20px;
        color: var(--primary-color);
        font-weight: 600;
        border: 1px solid var(--primary-color);
        justify-content: center;
        border-radius: 2px;
        &:hover{
            background: var(--primary-color);
            filter: none;
            color: white;
        }
    }
    &.small-btn{
        color: var(--green);
        font-size: 12px;
        justify-content: center;
        padding: 4px 8px;
        border-radius: 2px;
        background: transparent;
        border: 1px solid var(--green);
        &:hover{
            background: var(--green);
            color: white;
            filter: none;
        }
    }
    &.small-btn--filled{
        background: var(--green);
        font-size: 12px;
        justify-content: center;
        padding: 4px 8px;
        border-radius: 2px;
        border: 1px solid var(--green);
        &:hover{
            background: var(--green);
            filter: brightness(1.1);
            color: white;
        }
    }
    &.green{
        background: var(--green);
        font-size: 20px;
        justify-content: center;
        &:hover{
            background: var(--green);
            filter: brightness(1.1);
        }
    }
    &:disabled,
    &.disabled{
        pointer-events: none;
        opacity: .7;
    }
    .im-icon{
        font-size: $fontsize-xxs;
        margin-right: 7px;
    }
    &.cancel{
        padding: 8px 11px;
        background: #ffffff;
        color: #606060;
        font-size: $fontsize-s;
        border: 1px solid #606060;
    }
    &.confirm{
        padding: 8px 11px;
        background: var(--red-light);
        color: #ffffff;
        font-size: $fontsize-s;
        border: 1px solid var(--red-light);
    }
}
.button-link, a{
    color: var(--secondary);
    height: 0%;
    display: inline-flex;
    align-items: center;
    &:hover span{
        text-decoration: underline;
    }
    &:disabled,
    &.disabled{
        pointer-events: none;
        opacity: .7;
    }
}
button .im-icon{
    font-size: $fontsize-xxs;
    margin-right: 5px;
    text-decoration: none !important;
}
.export-btn .im-icon{
    font-size: $fontsize-xxs;
    margin-right: 5px;
}
.export-btn {
    text-decoration: none !important;
}
.export-btn:hover span {
    text-decoration: underline !important;
}
.button-row-sep{
    background: #dadddb;
    width: 1px;
    align-self: stretch;
}
.hightlight-hover{
    transition: .2s ease;
    &:hover{
        filter: brightness(1.1);
    }
}
.passive-link{
    color: #495058;
    text-decoration: underline;
    &:hover{
        color: #495058;
    }
}

// login
.page--login{
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active
    {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        background: white !important;
    }
    @-webkit-keyframes autofill {
        0%,100% {
            color: #666;
            background: transparent;
        }
    }

    input:-webkit-autofill {
        -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
        -webkit-animation-name: autofill;
        -webkit-animation-fill-mode: both;
    }
}

// modals
.modal{
    &.main-modal .modal-dialog{
        max-width: 600px;
    }
    &.detail-modal .modal-dialog{
        width: calc(100% - #{$spacing-xxl});
        height: calc(100% - #{$spacing-xxl});
        max-width: calc(1920px - #{$spacing-xxl});
        margin-top: $spacing-m;
        margin-bottom: $spacing-m;
        .modal-content{
            height: 100%;
            .theme--dark & {
                background-color: var(--background-color);
            }
        }
    }
    .modal-dialog{
        height: auto !important;
        overflow: auto !important;
        border-radius: 5px !important;
        margin-top: 60px;
        .modal-content{
            border: none;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
            overflow: hidden;
            min-height: 600px;
        }
        .modal-header {
            border: none;
            align-items: center;
        }
        .modal-body {
            border: none;
        }
        .modal-footer {
            border: none;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}

// forms
.row-form{
    margin-bottom: 20px;
    &:last-of-type{
        margin-bottom: 0;
    }
}

// fields
.field {
    .label{
        color: var(--text-color);
        font-size: $fontsize-m;
        display: inline-block;
        display: block;
        margin-bottom: 9px;
        font-weight: 600;
        span{
            font-weight: 300;
            color: #909090;
            margin-left: 3px;
        }
    }
    .after-string{
        margin-left: 12px;
        font-size: $fontsize-m;
        white-space: nowrap;
    }
    .error-field{
        border: 1px solid var(--red) !important;
    }
    .invalid-field{
        background-color: var(--red-background);
    }
}

.error-field-display {
    display: block;
    font-size: $fontsize-s;
    color: var(--red);
    line-height: normal;
    padding-top: 4px;
}

// datepicker
.myDpSelector{
    bottom: calc(100% + 4px)!important;
    top: auto !important;
    left: -1px !important;
    border: 1px solid #f1f1f1 !important;
    box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.2) !important;
    padding: 6px 7px !important;
    .theme--dark & {
        border-color: #6f747b !important;
    }
}
.myDpSelectorArrow, .myDpMonthYearSelBar, .myDpCalTable, .myDpMonthTable, .myDpYearTable, .myDpDaycell {
    background: #ffffff !important;
}
.myDpWeekDayTitle{
    color: var(--text-color) !important;
}
.myDpTableSingleDay:hover, .myDpTableSingleMonth:hover, .myDpTableSingleYear:hover {
    background: #f1f1f1 !important;
    .theme--dark & {
        background: #656c79 !important;
    }
}
.myDpMonthYearText button{
    color: var(--text-color) !important;
    pointer-events: none;
}
.myDpDayValue{
    color: var(--text-color) !important;
    transform: translatey(1px);
    display: block;
}
.myDpNextMonth .myDpDayValue, .myDpPrevMonth .myDpDayValue{
    color: #909090 !important;
}
body div .myDpSelectedDay, body div .myDpSelectedDay .myDpSelectedMonth, body div .myDpSelectedDay .myDpSelectedYear {
    border: none !important;
    background: var(--secondary) !important;
    &:hover{
        background: var(--secondary) !important;
    }
    border-radius: 3px !important;
    .myDpDayValue{
        color: white !important;
    }
}
.ng-mydp table td, .ng-mydp table th {
    border-radius: 3px;
}
.myDpMarkCurrDay, .myDpMarkCurrMonth, .myDpMarkCurrYear{
    border: none !important;
}
.myDpMarkCurrDay::after{
    content: "";
    width: 100%;
    height: calc(100% + 16px);
    background-color: #f1f1f1;
    position: absolute;
    left: 0;
    z-index: -1;
    right: 0;
    top: -9px;
    bottom: 0;
    border-radius: 3px;
    .theme--dark & {
        background-color: #656c79 !important;
        border-color: #6f747b;
    }
}
.myDpSelectedDay .myDpMarkCurrDay::after{
    background: transparent;
}
.myDpSelectorArrow:before{
    border-color: transparent !important;
    border-top-color: #ffffff !important;
    border-width: 8px !important;
    margin-left: -8px !important;
    top: 100% !important;
    z-index: 1 !important;
}
.myDpSelectorArrow:after{
    border-color: rgba(250,250,250,0) !important;
    border-top-color: #f1f1f1 !important;
    border-width: 9px !important;
    margin-left: -9px !important;
    top: 100% !important;
    .theme--dark & {
        border-top-color: #6f747b !important;
    }
}
.ng-mydp *{
    font-family: proxima-nova, sans-serif !important;
    font-size: $fontsize-s !important;
}
.myDpWeekDayTitle{
    font-size: $fontsize-s !important;
    font-weight: 600 !important;
}
.myDpIconLeftArrow {
    font-family: "imengine" !important;
}
.myDpIconRightArrow {
    font-family: "imengine" !important;
}
.myDpIconLeftArrow:before {
    content: "\ea03" !important;
}
.myDpIconRightArrow:before {
    content: "\ea04" !important;
}
.myDpIcon{
    font-size: 13px !important;
    color: var(--text-color) !important;
}
.myDpMonthYearText *{
    font-weight: 600 !important;
}
.myDpMonthYearSelBar{
    margin-bottom: 7px !important;
}
.myDpNoFooter {
    height: calc(100% - 35px) !important;
}
.myDpDaycell:focus, .myDpMonthcell:focus, .myDpYearcell:focus{
    box-shadow: inset 0 0 0 1px #f1f1f1 !important;
    .theme--dark & {
        box-shadow: inset 0 0 0 1px #656c79 !important;
    }
}

// bootstrap overwrites
.carousel-inner .carousel-item {
    transition: .3s ease !important;
}
.carousel-indicators{
    bottom: auto !important;
    top: calc(100% + 11px);
}
.carousel-indicators li{
    opacity: 1 !important;
    background: #afaeb3;
    &.active{
        background: var(--primary-color);
    }
    width: 8px;
    height: 8px;
    border-radius: 10px;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    padding: 0;
    border: none;
}
.dropdown-menu{
    min-width: 100%;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgba(144, 144, 144, 0.3) !important;
    margin: 0;
    padding: 0;
    border: none;
    z-index: 1;
    max-height: 306px;
    overflow-y: auto;
    background: #ffffff;
}
.dropdown-item{
    padding: 8px 10px;
    color: var(--text-color);
}
.dropdown-item.active, .dropdown-item:active{
    background: var(--primary-color);
}
.dropdown-item.active .typeahead-after, .dropdown-item:active .typeahead-after{
    color: #efefef;
}

@import "./assets/scss/tablet.scss";