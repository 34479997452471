.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease;
	animation-duration: .2s;
}
.fade-in-shimmer {
	opacity: 0;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease;
    animation-duration: .1s;
    animation-delay: .2s;
    animation-fill-mode: forwards;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


// modals

.modal-content{
	animation-name: modalAnimation;
	animation-duration: .1s;
}

@keyframes modalAnimation {
	0%   {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.modal-backdrop{
	animation-name: modalAnimationBackdrop;
    animation-duration: .4s;
    background-color: #696868;
    background-color: #606060;
}

@keyframes modalAnimationBackdrop {
	0%   {
		opacity: 0;
	}
	100% {
		opacity: .5;
	}
}

// ripple

// .btn {
// 	line-height: 31px;
// 	position: relative;
// 	padding: 5px 22px;
// 	border: 0;
// 	margin: 10px;
// 	cursor: pointer;
// 	border-radius: 2px;
// 	text-transform: uppercase;
// 	text-decoration: none;
// 	outline: none !important;
// 	-webkit-transition: 0.2s ease-out;
// 	-moz-transition: 0.2s ease-out;
// 	-o-transition: 0.2s ease-out;
// 	-ms-transition: 0.2s ease-out;
// 	transition: 0.2s ease-out;
//   }
  
//   .btn i,
//   .btn-flat i {
// 	font-size: 1.3rem;
// 	line-height: inherit;
//   }
  
//   .btn .badge {
// 	margin-left: 7px;
//   }
  
//   .z-depth-1, .btn, .btn-floating {
// 	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
//   }
  
//   .z-depth-1-half, .btn:hover, .btn-floating:hover {
// 	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
//   }
  
//   .btn-default {
// 	color: #fff;
// 	background-color: #2BBBAD;
//   }
  
//   .btn-default:hover,
//   .btn-default:focus {
// 	background-color: #30cfc0 !important;
// 	color: #fff !important;
//   }
  
  .waves-effect {
	position: relative;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	vertical-align: middle;
	z-index: 1;
	will-change: opacity, transform;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
  }
  
  .waves-effect .waves-ripple {
	position: absolute;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	margin-left: -10px;
	opacity: 0;
	background: rgba(0, 0, 0, 0.2);
	-webkit-transition: all 0.7s ease-out;
	-moz-transition: all 0.7s ease-out;
	-o-transition: all 0.7s ease-out;
	-ms-transition: all 0.7s ease-out;
	transition: all 0.7s ease-out;
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	-o-transition-property: -o-transform, opacity;
	transition-property: transform, opacity;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	pointer-events: none;
  }
  
  .waves-effect.waves-light .waves-ripple {
	background-color: rgba(255, 255, 255, 0.45);
  }

  .waves-effect.waves-mediumlight .waves-ripple {
	background-color: rgba(227, 227, 227, 0.45);
  }

  .waves-effect.waves-mediumlighter .waves-ripple {
	background-color: rgba(234, 234, 234, 0.45);
  }
  
  .waves-effect.waves-red .waves-ripple {
	background-color: rgba(244, 67, 54, 0.7);
  }
  
  .waves-effect.waves-yellow .waves-ripple {
	background-color: rgba(255, 235, 59, 0.7);
  }
  
  .waves-effect.waves-orange .waves-ripple {
	background-color: rgba(255, 152, 0, 0.7);
  }
  
  .waves-effect.waves-purple .waves-ripple {
	background-color: rgba(156, 39, 176, 0.7);
  }
  
  .waves-effect.waves-green .waves-ripple {
	background-color: rgba(76, 175, 80, 0.7);
  }
  
  .waves-effect.waves-teal .waves-ripple {
	background-color: rgba(0, 150, 136, 0.7);
  }
  
  /* Firefox Bug: link not triggered */
  a.waves-effect .waves-ripple {
	z-index: -1;
  }